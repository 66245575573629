@if (show){
<div class="alert toast-alert alert-sm toast-danger" role="alert"
    [ngClass]="alertErrorName === alertErrorNameConst.main ? 'toast-main': 'toast-dialog'">
    <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
    </button>
    <span class="vismaicon vismaicon-filled vismaicon-error" aria-hidden="true"></span>
    <span class="sr-only">Error:</span>

    @if (alertErrorMessage.errors.length < maximumMessagesDisplayedWithoutScroll){ <div class="alert-msg-text"
        style="word-wrap: break-word;">
        @for (item of alertErrorMessage.errors; track $index) {
        <p>
            {{item.message}}
            @if(item.status === 500 && item.guid && item.guid.length > 0) {
            <span><br />{{errorCode}}: {{item.guid}}</span>
            }
        </p>
        }
</div>
} @else {
<div class="alert-msg-text scrollable" style="word-wrap: break-word;">
    @for (item of alertErrorMessage.errors; track $index) {
    <p>
        {{item.message}}
        @if(item.status === 500 && item.guid && item.guid.length > 0) {
        <span><br />{{errorCode}}: {{item.guid}}</span>
        }
    </p>
    }
</div>
}
</div>
}
