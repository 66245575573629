import { HttpInterceptorFn } from '@angular/common/http';
import { LoadingWidgetDataService } from '../loading-widget/loading-widget-data.service';
import { inject } from '@angular/core';
import { finalize } from 'rxjs';
import { DomainService } from '../domain/domain.service';

export const loadingInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingWidgetDataService = inject(LoadingWidgetDataService);
  const domainService = inject(DomainService);
  if (domainService.isConnectUrl(req.url) || domainService.skipSpinner(req.urlWithParams)) {
    return next(req);
  } else {
    loadingWidgetDataService.incrementCount();
    return next(req)
      .pipe(
        finalize(() => {
          loadingWidgetDataService.decrementCount();
        })
      );
  }
};
