export const DEFAULT_MILLISECONDS = 2000;

export class AlertFlashMessage {
    alertFlashName: string = '';
    type: string = '';
    static readonly TYPE = {
        success: 'success',
        info: 'info',
        warning: 'warning'
    };
    text: string = '';
}


