@if(rootPath){
<details class="collapse-list">
    <summary class="collapse-list-header" id="heading1" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['customerHelpNote1']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['customerAltNote1']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/01_user_access_management.png" [alt]="message['customerAltNote1']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading2" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title ">
            <span class="caret"></span>
            {{message['customerHelpNote2']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['customerAltNote2']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/02_user_selected.png" [alt]="message['customerAltNote2']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading3" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['customerHelpNote3']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['customerAltNote3']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/03_company_selected.png" [alt]="message['customerAltNote3']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading4" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['customerHelpNote4']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['customerAltNote4']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/04_role_selected.png" [alt]="message['customerAltNote4']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading5" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['customerHelpNote5']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['customerAltNote5']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/05_user_and_company_selected.png" [alt]="message['customerAltNote5']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading6" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['customerHelpNote6']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['customerAltNote6']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/06_role_assignment.png" [alt]="message['customerAltNote6']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading7" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['customerHelpNote7']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['customerAltNote7']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/07_user_link.png" [alt]="message['customerAltNote7']" width="100%" />
        <p></p>
        <img src="{{rootPath}}/08_user_detail.png" [alt]="message['customerAltNote8']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading9" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['customerHelpNote9']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['customerAltNote9']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src=".{{rootPath}}/09_company_and_role_link.png" [alt]="message['customerAltNote9']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading10" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['customerHelpNote10']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['customerAltNote10']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/10_user_group_list.png" [alt]="message['customerAltNote10']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading11" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['customerHelpNote11']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['customerAltNote11']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src=".{{rootPath}}/11_company_and_role_group_list.png" [alt]="message['customerAltNote11']" width="100%" />
        <p></p>
    </div>
</details>
}
