export const ALERT_FLASH_NAME = {
    main: 'mainFlashAlert',
    dialogTabDetails: 'dialogTabDetailsFlashAlert',
    dialogTabMembers: 'dialogTabMembersFlashAlert',
    dialogTabServices: 'dialogTabServicesFlashAlert',
    dialogTabChangeLog: 'dialogTabServicesFlashAlert',
    dialogTabSubscriptions: 'dialogTabSubscriptionsFlashAlert',
    dialogTabConfiguration: 'dialogTabConfigurationFlashAlert',
    dialogTabServiceAccess: 'dialogTabServiceAccessFlashAlert'
}
