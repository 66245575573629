import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { PATH } from '../const/path';
import { DomainService } from '../domain/domain.service';
import { WindowRef } from '../domain/window-ref';
import { AlertErrorDataService } from '../../alert/alert-error/alert-error-data.service';
import { AlertErrorMessage } from '../../alert/alert-error/alert-error-message';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService {

  constructor(private readonly auth: AuthService, private readonly windowRef: WindowRef, private readonly router: Router,
    private readonly alertErrorDataService: AlertErrorDataService, private readonly domain: DomainService) { }

  handleError(error: any) {

    if (error instanceof HttpErrorResponse) {
      if (!this.windowRef.isOnline()) {
        this.router.navigateByUrl(PATH.no_connection);
      } else if (error.status === 401) {
        this.auth.handleUnauthorized();
      } else if (error.status === 403) {
        this.router.navigateByUrl(PATH.forbidden);
      } else if (error.status === 0) {
        console.error(error);
      } else {
        if (error.url && !this.domain.isUrlExcludedFromRetryStrategy(error.url)) {
          this.alertErrorDataService.addAlertMessage(this.alertErrorDataService.alertErrorName, AlertErrorMessage.TYPE.http, error);
        }
      }
    } else {
      //A client-side or network error occurred.
      if (error) {
        console.error(error);
      }
    }
  }
}
