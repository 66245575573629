import { Tenant } from "../core/auth/tenant";

export class ContextGroup {
    label: string = '';
    type: string = '';
    items: Tenant[] = [];

    constructor(label: string, type: string, items: Tenant[]) {
        this.label = label;
        this.type = type;
        this.items = items;
    }
}
