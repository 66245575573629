import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WelcomeDialogDataService {

  private _closeDialog = new Subject<boolean>();
  public closeDialog$: Observable<boolean> = this._closeDialog.asObservable();

  private _openHelpPanel = new Subject<boolean>();
  public openHelpPanel$: Observable<boolean> = this._openHelpPanel.asObservable();

  closeDialog(): void {
    this._closeDialog.next(true);
  }

  openHelpPanel(): void {
    this._openHelpPanel.next(true);
  }
}
