<div class="discard-mask">
    <div class="discard-dialog modal fade in" tabindex="-1" role="dialog" aria-labelledby="modal-title"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{message['headerWelcomeDialog']}}</h4>
                </div>
                <div class="modal-body">
                    <p>{{message['infoLaunchNewAdmin1']}}</p>
                    <p>{{message['infoLaunchNewAdmin2']}}</p>
                </div>
                <div class="modal-footer justify-content-end d-flex">
                    <button type="button" class="btn btn-primary"
                        (click)="showMeChanges()">{{message['buttonShowMeWhatsNew']}}</button>
                    <button type="button" class="btn btn-default"
                        (click)="cancel()">{{message['buttonIllExploreOnMyOwn']}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

