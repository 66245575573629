import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { inject } from '@angular/core';
import { PATH } from '../const/path';

export const authGuard: CanActivateFn = (route, state) => {
  if (inject(AuthService).isAuthenticated()) {
    return true;
  } else {
    sessionStorage.clear();
    inject(Router).navigateByUrl(PATH.login);
    return false;
  }

};
