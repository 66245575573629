import { Injectable } from '@angular/core';
import { ConnectIdentityConfig } from './connect-identity-config';
import { HOSTNAME } from './hostname';
import { ENV } from './env';
import { environment } from '../../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor() { }
  
  getConnectIdentityConfig(hostname: string): ConnectIdentityConfig {
    const connectIdentity: { [key: string]: any } = environment.connectIdentity;
    const env = this.getEnviroment(hostname);
    const config: ConnectIdentityConfig = connectIdentity[env];
    return config;
  }

  getBaseUrl(hostname: string): string {
    const env = this.getEnviroment(hostname);
    const api: { [key: string]: string } = environment.api;
    return api[env];
  }

  getVismaHomeUri(hostname: string): string {
    const env = this.getEnviroment(hostname);
    const uri: { [key: string]: string } = environment.vismaHomeUri;
    return uri[env];
  }

  isUrlExcludedFromRetryStrategy(url: string): boolean {
    const arr: string[] = environment.excludedUrlsFromRetryStrategy;
    return this.indexOf(url, arr);
  }
  
  isConnectUrl(url: string): boolean {
    return this.isFromConnect(url, 'connect.') || this.isFromConnect(url, 'execute-api.eu-west-1.amazonaws.com');
  }

  isConnectUserInfo(url: string): boolean {
    return this.isFromConnect(url, '/connect/userinfo') ||
      this.isFromConnect(url, '/oauth2/userInfo');
  }

  isConnectTenants(url: string): boolean {
    return this.isFromConnect(url, '/v1.0/tenants');
  }

  skipSpinner(url: string): boolean {
    return url.indexOf('skipSpinner=true') > -1;
  }

  isTestable(hostname: string, userEmail: string): boolean {
    return this.isTestHostname(hostname) && environment.testUsers.indexOf(userEmail) > -1;
  }

  getEnviroment(hostname: string): string {
    switch (hostname) {
      case HOSTNAME.int: return ENV.int;
      case HOSTNAME.stag: return ENV.stag;
      case HOSTNAME.prod: return ENV.prod;
      default: return ENV.localhost;
    }
  }

  private isFromConnect(url: string, segment: string): boolean {
    let is = false;
    if (url.indexOf(segment) > -1) {
      is = true;
    }
    return is;
  }

  private indexOf(str: string, arr: string[]): boolean {
    let is = false;
    for (let i = 0; i < arr.length; i++) {
      if (str.indexOf(arr[i]) > -1) {
        is = true;
        break;
      }
    }
    return is;
  }

  private isTestHostname(hostname: string): boolean {
    return hostname === HOSTNAME.localhost || hostname === HOSTNAME.int || hostname === HOSTNAME.stag;
  }
}
