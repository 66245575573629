import { Routes } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { authGuard } from './common/core/auth/auth.guard';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'context',
        pathMatch: 'full',
    },
    {
        path: "login",
        loadComponent: () => import('./common/core/auth/login/login.component').then(m => m.LoginComponent)
    },
    {
        path: 'callback',
        loadComponent: () => import('./common/core/auth/callback/callback.component').then(m => m.CallbackComponent)
    },
    {
        path: 'logout',
        loadComponent: () => import('./common/core/auth/logout/logout.component').then(m => m.LogoutComponent)
    },
    {
        path: 'connect-logout',
        loadComponent: () => import('./common/core/auth/connect-logout/connect-logout.component').then(m => m.ConnectLogoutComponent)
    },
    {
        path: '',
        component: LayoutComponent,
        canActivateChild: [authGuard],
        children: [
            {
                path: 'context',
                loadChildren: () => import('./context/context.routes').then(r => r.CONTEXT_ROUTES)
            }
        ]
    },
    {
        path: 'no-connection',
        loadComponent: () => import('./common/core/error/no-connection/no-connection.component').then(m => m.NoConnectionComponent)
    },
    {
        path: 'forbidden',
        loadComponent: () => import('./common/core/error/forbidden/forbidden.component').then(m => m.ForbiddenComponent)
    },
    {
        path: '**',
        loadComponent: () => import('./common/core/error/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent)
    }
];
