import { Component, computed } from '@angular/core';
import { LoadingWidgetDataService } from './loading-widget-data.service';

@Component({
  selector: 'adminui-loading-widget',
  standalone: true,
  imports: [],
  templateUrl: './loading-widget.component.html',
})
export class LoadingWidgetComponent {

  counter = this.loadingWidgetDataService.counter;
  show = computed(() => this.counter() > 0);

  constructor(private readonly loadingWidgetDataService: LoadingWidgetDataService) { }
}
