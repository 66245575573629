import { HttpRequest, HttpEvent, HttpHeaders, HttpHandlerFn } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, catchError, throwError } from 'rxjs';
import { TOKEN_RESPONSE } from '../auth/connect-storage-keys';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private authService: AuthService) { }

  handle401(request: HttpRequest<any>, next: HttpHandlerFn, refreshToken: string, locale: string): Observable<HttpEvent<any>> {
    return this.authService.refreshToken(refreshToken).pipe(
      switchMap((response) => {
        sessionStorage.setItem(TOKEN_RESPONSE, JSON.stringify(response));
        let headers = new HttpHeaders({ 'Cache-Control': 'no-cache', 'Expires': '0', 'Content-Type': 'application/json' });
        headers = headers.append('Accept-Language', locale);
        headers = headers.append('Authorization', `Bearer ${response.access_token}`);
        const clone = request.clone({
          headers: headers,
          withCredentials: true
        })
        return next(clone);
      }),
      catchError((error) => {
        this.authService.handleUnauthorized();
        return throwError(() => error);
      })
    );
  }

}
