import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WindowRef } from './common/core/domain/window-ref';
import { GlobalErrorHandlerService } from './common/core/error/global-error-handler.service';
import { baseUrlInterceptor } from './common/core/http-interceptor/base-url.interceptor';
import { headerInterceptor } from './common/core/http-interceptor/header.interceptor';
import { loadingInterceptor } from './common/core/http-interceptor/loading.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserAnimationsModule),
    provideRouter(routes),
    provideHttpClient(withInterceptors(
      [headerInterceptor, baseUrlInterceptor, loadingInterceptor],
    )),
    WindowRef,
    GlobalErrorHandlerService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    }
  ]
};
