@if(show === true){
<div role="alert"
    [ngClass]="{'toast-main': alertFlashName === alertFlashNameConst.main, 'toast-dialog': alertFlashName !== alertFlashNameConst.main}"
    class="alert toast-main toast-alert alert-sm alert-dismissible fade in" [class]="alertClass">
    <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
    </button>
    <div>
        <span [class]="iconClass" class="vismaicon vismaicon-filled"></span>
        <span>{{text}}</span>
    </div>
</div>
}
