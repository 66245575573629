import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, computed, signal } from '@angular/core';
import { ALERT_ERROR_NAME } from './alert-error-name';
import { AlertErrorDataService } from './alert-error-data.service';
import { NgClass } from '@angular/common';
import { AlertErrorMessage } from './alert-error-message';
import { Subscription } from 'rxjs';

@Component({
  selector: 'adminui-alert-error',
  standalone: true,
  imports: [NgClass],
  templateUrl: './alert-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertErrorComponent implements OnInit, OnDestroy {

  @Input({ alias: 'alertErrorName', required: true }) alertErrorName: string = '';

  errorCode = '';
  show = false;
  alertErrorMessage!: AlertErrorMessage;

  readonly maximumMessagesDisplayedWithoutScroll = 5;
  readonly alertErrorNameConst = ALERT_ERROR_NAME;
  readonly alertErrorMessageType = AlertErrorMessage.TYPE;

  private alertErrorMessage$ = new Subscription();

  constructor(private readonly alertErrorDataService: AlertErrorDataService, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.changeDetectorRef.detach();
    this.alertErrorDataService.alertErrorName = this.alertErrorName;
    
    this.alertErrorMessage$ = this.alertErrorDataService.alertMessage$.subscribe(alertErrorMessage => {
      if (alertErrorMessage.alertErrorName === this.alertErrorName && alertErrorMessage.type === this.alertErrorMessageType.http) {
        this.alertErrorMessage = alertErrorMessage;
        this.errorCode = alertErrorMessage.errorCode;
        this.show = true;
        this.changeDetectorRef.detectChanges();
      }
    });
  }
  
  ngOnDestroy(): void {
    this.alertErrorMessage$.unsubscribe();
  }

  close(): void {
    this.show = false;
    this.changeDetectorRef.detectChanges();
  }
}
