import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingWidgetDataService {

  counter = signal<number>(0);

  incrementCount() {
    this.counter.update(value => value + 1);
  }

  decrementCount() {
    this.counter.update(value => value - 1);
  }

}
