@if (hasProfile){
<div id="page" class="admin-ui-page">
    <adminui-navbar></adminui-navbar>

    @if (showWelcomeDialog){
    <adminui-welcome-dialog></adminui-welcome-dialog>
    }

    <main role="main">
        <router-outlet></router-outlet>

        @if (showNotification){
        <div role="alert" class="alert toast-alert toast-main alert-info alert-sm alert-dismissible fade in">
            <button type="button" class="close" (click)="closeNotification()">
                <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
            </button>
            <a href="#" rel="noopener noreferrer" (click)="onClickNotification($event)">
                <div>
                    <span>{{notificationTitle}}</span>
                </div>
            </a>
        </div>
        }

        <adminui-loading-widget></adminui-loading-widget>
        <adminui-alert-flash alertFlashName="mainFlashAlert"></adminui-alert-flash>
        <adminui-alert-error alertErrorName="mainErrorAlert"></adminui-alert-error>

    </main>

    <footer>
    </footer>
</div>
}
