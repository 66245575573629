import { HttpErrorResponse, HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { TOKEN_RESPONSE } from '../auth/connect-storage-keys';
import { TokenResponse } from '../auth/token-response';
import { ProfileService } from '../auth/profile.service';
import { catchError, throwError } from 'rxjs';
import { HelperService } from './helper.service';
import { DomainService } from '../domain/domain.service';

export const headerInterceptor: HttpInterceptorFn = (req, next) => {
  const domainService = inject(DomainService);
  const profileService = inject(ProfileService);
  const helperService = inject(HelperService);

  if (domainService.isConnectUrl(req.url)) {
    if (domainService.isConnectUserInfo(req.url) || domainService.isConnectTenants(req.url)) {
      const str = sessionStorage.getItem(TOKEN_RESPONSE);
      if (str) {
        const tr: TokenResponse = JSON.parse(str);
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', `Bearer ${tr.access_token}`);
        const clone = req.clone({
          headers: headers
        });
        return next(clone);
      }
      return next(req);
    } else {
      return next(req);
    }
  } else {
    let headers = new HttpHeaders({ 'Cache-Control': 'no-cache', 'Expires': '0', 'Content-Type': 'application/json' });

    let locale = 'en-US';
    if (profileService.profile) {
      locale = profileService.profile.locale;
    }
    headers = headers.append('Accept-Language', locale);

    const str = sessionStorage.getItem(TOKEN_RESPONSE);
    let refreshToken = '';
    if (str) {
      const tr: TokenResponse = JSON.parse(str);
      refreshToken = tr.refresh_token;
      headers = headers.append('Authorization', `Bearer ${tr.access_token}`);
    }

    const clone = req.clone({
      headers: headers,
      withCredentials: true
    });
    return next(clone).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401 && refreshToken !== '') {
          return helperService.handle401(clone, next, refreshToken, locale);
        }
        return throwError(() => error);
      })
    );
  }

};
