import { Component, Renderer2 } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { Subscription, first, forkJoin, catchError, of } from 'rxjs';
import { environment } from '../environments/environment.prod';
import { ALERT_ERROR_NAME } from './common/alert/alert-error/alert-error-name';
import { ALERT_FLASH_NAME } from './common/alert/alert-flash/alert-flash-name';
import { AuthService } from './common/core/auth/auth.service';
import { ADMIN_CUSTOMER_UI_THEME, LAST_USED_TENANT_ID } from './common/core/auth/custom';
import { Profile } from './common/core/auth/profile';
import { PROFILE_ROLE } from './common/core/auth/profile-role';
import { ProfileService } from './common/core/auth/profile.service';
import { ADMIN_UI_CONNECT_LOGOUT } from './common/core/auth/storage-keys';
import { ConfigService } from './common/core/config/config.service';
import { DomainService } from './common/core/domain/domain.service';
import { HOSTNAME } from './common/core/domain/hostname';
import { WindowRef } from './common/core/domain/window-ref';
import { I18NService } from './common/core/i18n/i18n.service';
import { LoadingWidgetComponent } from './common/core/loading-widget/loading-widget.component';
import { NavbarComponent } from './common/navbar/navbar.component';
import { NotificationItem } from './notification-item';
import { NotificationsHub } from './notifications-hub';
import { WootricService } from './web-workers/wootric/wootric.service';
import { WelcomeDialogDataService } from './welcome-dialog-data.service';
import { WelcomeDialogComponent } from './welcome-dialog.component';
import { AlertErrorComponent } from './common/alert/alert-error/alert-error.component';
import { AlertFlashComponent } from './common/alert/alert-flash/alert-flash.component';


@Component({
  selector: 'adminui-layout',
  standalone: true,
  imports: [RouterModule, AlertFlashComponent, AlertErrorComponent, LoadingWidgetComponent, WelcomeDialogComponent, NavbarComponent],
  templateUrl: './layout.component.html',
})
export class LayoutComponent {

  alertFlashName = ALERT_FLASH_NAME;
  alertErrorName = ALERT_ERROR_NAME;

  unlistenerStorage: any;
  hasProfile = false;
  showWelcomeDialog = false;
  notificationTitle: string = '';
  profileRole = PROFILE_ROLE;

  notificationItem!: NotificationItem | null;
  showNotification = false;

  message: { [key: string]: string } = {};

  private notificationTimeout: any;
  private webSocket!: WebSocket;
  private pingInterval!: any;

  private wellKnownConfig$ = new Subscription();
  private userInfo$ = new Subscription();
  private showWelcomeDialog$ = new Subscription();


  constructor(private authService: AuthService, private profileService: ProfileService, private renderer2: Renderer2,
    private windowRef: WindowRef, private wootricService: WootricService, private configService: ConfigService,
    private welcomeDialogDataService: WelcomeDialogDataService, private i18nService: I18NService,
    private domainService: DomainService, private router: Router) { }

  ngOnInit(): void {

    this.unlistenerStorage = this.renderer2.listen('window', 'storage', (event: StorageEvent) => {
      if (event.key && event.key === ADMIN_UI_CONNECT_LOGOUT) {
        localStorage.removeItem(ADMIN_UI_CONNECT_LOGOUT);
        this.authService.handleUnauthorized();
      }
    });

    const hostname = this.windowRef.getHostname();

    this.userInfo$ = this.authService.getUserProfile().pipe(first()).subscribe(profile => {
      this.profileService.profile = profile;
      this.handleI18N();

      forkJoin({
        tenantResponse: this.authService.getTenantResponseById(hostname, profile.custom[LAST_USED_TENANT_ID]),
        def: this.authService.getTranslation(environment.defaultLocale),
        t: this.authService.getTranslation(this.profileService.profile.locale)
      }).subscribe(({ tenantResponse, def, t }) => {
        this.profileService.profile.currentTenant = tenantResponse.tenants[0];

        //remove this line when the roles are available in the tenant response
        //this.profileService.profile.currentTenant.roles = [PROFILE_ROLE.companyAdministrator];

        this.i18nService.messages = this.i18nService.extend(def, t);
        this.handleTheme();
        this.handleProfile(hostname, profile, this.profileService.profile.currentTenant.roles[0]);
      });
    });
  }

  ngOnDestroy(): void {
    this.wellKnownConfig$.unsubscribe();
    this.userInfo$.unsubscribe();
    this.showWelcomeDialog$.unsubscribe();
    this.wootricService.terminate();
    if (this.webSocket) {
      this.webSocket.close();
    }
  }

  onClickNotification(event: Event): void {
    event.preventDefault();
    if (this.notificationItem !== null) {
      this.windowRef.open(this.notificationItem.url, '_blank');
    }
  }

  closeNotification() {
    this.hideNotification();
  }

  private handleProfile(hostname: string, profile: Profile, currentRole: string): void {

    this.profileService.profile.currentRole = currentRole;
    this.profileService.profile.userDetailsUri = this.authService.getUserDetailsUri(hostname);

    if (currentRole === PROFILE_ROLE.subscriberAdministrator) {
      this.handleProfileForSubscriberAdministrator(profile, hostname);
    } else if (currentRole === PROFILE_ROLE.companyAdministrator) {
      this.handleProfileForCompanyAdministrator(profile, hostname);
    }
  }

  private handleProfileForSubscriberAdministrator(profile: Profile, hostname: string): void {
    forkJoin({
      flags: this.configService.getFlags().pipe(catchError(err => {
        return of({ userFlags: [] });
      })),
      aoSetup: this.configService.hasAOSetup().pipe(catchError(err => {
        return of({ customerIsAO: false });
      }))
    }).subscribe(({ flags, aoSetup }) => {
      this.profileService.userFlags = flags.userFlags;
      this.profileService.isAOCustomer = aoSetup.customerIsAO;
      this.profileService.isTestable = this.domainService.isTestable(hostname, this.profileService.profile.email);
      this.hasProfile = true;
      this.handleDependencies(profile);
      this.router.navigateByUrl(this.authService.getRoutingPathFromRoles([this.profileService.profile.currentRole]));
    });
  }

  private handleProfileForCompanyAdministrator(profile: Profile, hostname: string): void {
    this.profileService.userFlags = [];
    this.profileService.isTestable = this.domainService.isTestable(hostname, this.profileService.profile.email);
    this.hasProfile = true;
    this.handleDependencies(profile);
    this.router.navigateByUrl(this.authService.getRoutingPathFromRoles([this.profileService.profile.currentRole]));
  }

  private handleDependencies(profile: Profile): void {
    const msg = this.i18nService.messages['wootric'];
    this.wootricService.init(msg['disclaimerText'], msg['disclaimerLink']);
    // this.showWelcomeDialog = this.authService.showWelcomeDialog(profile.custom);
    // if (this.showWelcomeDialog) {
    //   this.authService.patchCustomWithWelcomeDialogSeen().subscribe();
    // }
    // this.onCloseWelcomeDialog();
    this.initWebSocket();
  }

  private handleTheme(): void {
    let theme = this.profileService.profile.custom[ADMIN_CUSTOMER_UI_THEME];
    if (!theme) {
      theme = this.profileService.getTheme();
      this.authService.patchCustomWithTheme(theme).subscribe();
    }
    this.profileService.initTheme(theme);
    this.profileService.profile.theme = theme;
  }

  private handleI18N(): void {
    const locale = this.i18nService.getLocale(environment.supportedLocale,
      environment.defaultLocale, this.profileService.profile.locale);
    this.profileService.profile.locale = locale;
  }

  private onCloseWelcomeDialog(): void {
    this.showWelcomeDialog$ = this.welcomeDialogDataService.closeDialog$.subscribe(() => {
      this.showWelcomeDialog = false;
    });
  }


  private initWebSocket(): void {
    const hostname = this.windowRef.getHostname();

    if (hostname !== HOSTNAME.int) {
      const access_token = this.authService.getAccessToken();
      if (access_token !== null) {
        const msg = this.i18nService.messages['notifications'];

        this.webSocket = new WebSocket(this.getConnectionUrl(hostname, access_token));
        this.webSocket.onopen = () => {
          console.log('WebSocket on open');
          this.pingInterval = setInterval(() => {
            console.log("WebSocket send ping");
            const sendMessage = JSON.stringify({ action: 'ping' });
            this.webSocket.send(sendMessage);
          }, 9 * 60000);
        };

        this.webSocket.onmessage = (event: any) => {
          this.notificationItem = JSON.parse(event.data);
          if (this.notificationItem && this.notificationItem.entityName && this.notificationItem.exportType) {
            this.notificationTitle = this.i18nService.getFormattedMessage(msg['infoYourExportIsReady'],
              [msg[this.notificationItem.exportType], this.notificationItem.entityName]);
            this.showNotification = true;
            this.notificationTimeout = setTimeout(() => {
              this.hideNotification();
            }, 30000);
          }
        };

        this.webSocket.onerror = (event: any) => {
          console.error("WebSocket error received: ", event);
          clearInterval(this.pingInterval);
        }

        this.webSocket.onclose = (event: any) => {
          console.log("WebSocket closed: ", event);
          clearInterval(this.pingInterval);
        }
      }
    }
  }

  private hideNotification(): void {
    this.showNotification = false;
    this.notificationItem = null;
    clearTimeout(this.notificationTimeout);
  }

  private getConnectionUrl(hostname: string, accessToken: string): string {
    const env = this.domainService.getEnviroment(hostname);
    const nhub = <{ [key: string]: NotificationsHub }>environment.notificationsHub;
    return `${nhub[env].url}?access-token=${accessToken}`;
  }

}


