import { Injectable } from "@angular/core";

function _window(): Window {
    return window;
}

@Injectable()
export class WindowRef {

    get nativeWindow(): Window {
        return _window();
    }

    setLocationHref(url: string): void {
        this.nativeWindow.location.href = url;
    }

    open(url: string, target: string): void {
        this.nativeWindow.open(url, target);
    }

    getPathname(): string {
        return this.nativeWindow.location.pathname;
    }

    getSearch(): string {
        return this.nativeWindow.location.search;
    }

    getHostname(): string {
        return this.nativeWindow.location.hostname;
    }

    isOnline(): boolean {
        return this.nativeWindow.navigator.onLine;
    }
}
