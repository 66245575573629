import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ProfileService } from '../auth/profile.service';
import { PROFILE_ROLE } from '../auth/profile-role';
import { CommonModule } from '@angular/common';
import { HelpCustomerComponent } from './help-customer/help-customer.component';
import { HelpCompanyComponent } from './help-company/help-company.component';

@Component({
  selector: 'adminui-help',
  standalone: true,
  imports: [CommonModule, HelpCustomerComponent, HelpCompanyComponent],
  templateUrl: './help.component.html',
})
export class HelpComponent implements OnInit, OnDestroy {

  @Input({ alias: 'show', required: true }) show: boolean = false;
  @Output() onClose = new EventEmitter<boolean>();
  @Input({ alias: 'message', required: true }) message: { [key: string]: string; } = {};
  @ViewChild('closeButton') closeButton!: ElementRef;

  rootPath!: string;
  currentRole!: string; 
  profileRole = PROFILE_ROLE; 

  constructor(private profileService: ProfileService) { }

  ngOnInit() {
    this.currentRole = this.profileService.profile.currentRole;
    this.rootPath = this.getHelpRootPath(this.currentRole);
  }

  ngOnDestroy(): void {
    this.onClose.unsubscribe();
  }

  close() {
    this.show = false;
    this.onClose.emit(true);
  }

  private getHelpRootPath(currentRole: string): string {
    let helpRootPath = '';
    const locale = this.getLocale();
    switch (currentRole) {
      case PROFILE_ROLE.subscriberAdministrator:
        helpRootPath = `./assets/images/help/customer/${locale}`;
        break;
      case PROFILE_ROLE.companyAdministrator:
        helpRootPath = `./assets/images/help/company/en-US`;
        break;
    }
    return helpRootPath;
  }

  private getLocale(): string {
    let locale = this.profileService.profile.locale;
    if (locale !== 'en-US' && locale !== 'da-DK' && locale !== 'nl-NL'
      && locale !== 'fi-FI' && locale !== 'nb-NO' && locale !== 'sv-SE') {
      locale = 'en-US';
    }
    return locale;
  }

}
