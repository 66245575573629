export const PATH = {
    root: '/',
    login: '/login',
    logout: '/logout',
    connect_logout: '/connect-logout',
    no_connection: '/no-connection',
    forbidden: '/forbidden',
    context: '/context',
    context_company: '/context/company',
    context_customer: '/context/customer',
    context_company_user_access: '/context/company/user-access',
    context_customer_user_access: '/context/customer/user-access'
}
