@if(rootPath){
<details class="collapse-list">
    <summary class="collapse-list-header" id="heading1" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['companyHelpNote1']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['companyAltNote1']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/01_user_access_management.png" [alt]="message['companyAltNote1']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading2" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title ">
            <span class="caret"></span>
            {{message['companyHelpNote2']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['companyAltNote2']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/02_user_selected.png" [alt]="message['companyAltNote2']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading3" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['companyHelpNote3']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['companyAltNote3']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/03_user_detail.png" [alt]="message['companyAltNote3']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading4" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['companyHelpNote4']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['companyAltNote4']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/04_company_details.png" [alt]="message['companyAltNote4']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading5" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['companyHelpNote5']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['companyAltNote5']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/05_role_selected.png" [alt]="message['companyAltNote5']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading6" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['companyHelpNote6']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['companyAltNote6']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/06_role_group_selected.png" [alt]="message['companyAltNote6']" width="100%" />
        <p></p>
    </div>
</details>

<details class="collapse-list">
    <summary class="collapse-list-header" id="heading7" [tabindex]="show ? 0: -1">
        <div class="collapse-list-title">
            <span class="caret"></span>
            {{message['companyHelpNote7']}}
            <img class="thumbnail" src="./assets/images/help/screen_thumb.png" [alt]="message['companyAltNote7']" />
        </div>
    </summary>
    <div class="collapse-list-content">
        <img src="{{rootPath}}/07_role_assignment.png" [alt]="message['companyAltNote7']" width="100%" />
    </div>
</details>
}
