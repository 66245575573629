import { CommonModule } from '@angular/common';
import { Component, ElementRef, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AutoCompleteModule, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { Subscription, first, fromEvent, debounceTime } from 'rxjs';
import { WelcomeDialogDataService } from '../../welcome-dialog-data.service';
import { AuthService } from '../core/auth/auth.service';
import { Profile } from '../core/auth/profile';
import { PROFILE_ROLE } from '../core/auth/profile-role';
import { ProfileService } from '../core/auth/profile.service';
import { Tenant } from '../core/auth/tenant';
import { THEME } from '../core/auth/theme';
import { HOSTNAME } from '../core/domain/hostname';
import { WindowRef } from '../core/domain/window-ref';
import { I18NService } from '../core/i18n/i18n.service';
import { Application } from './application';
import { AUTOCOMPLETE_MAX_LIMIT } from './autocomplete-limit';
import { NavbarService } from './navbar.service';
import { DropdownModule } from 'primeng/dropdown';
import { ContextGroup } from './context-group';
import { HelpComponent } from '../core/help/help.component';


@Component({
  selector: 'adminui-navbar',
  standalone: true,
  imports: [CommonModule, FormsModule, AutoCompleteModule, RouterLink, RouterLinkActive, DropdownModule, HelpComponent],
  templateUrl: './navbar.component.html'
})
export class NavbarComponent {

  @ViewChild('appsToggle') appsToggle!: ElementRef;
  @ViewChild('contextsToggle') contextsToggle!: ElementRef;
  @ViewChild('contextsMenu') contextsMenu!: ElementRef;
  @ViewChild('navbarDefaultHeader') navbarDefaultHeader!: ElementRef;
  @ViewChild('navbarBrandDiv') navbarBrandDiv!: ElementRef;
  @ViewChild('navbarBrandLi') navbarBrandLi!: ElementRef;
  @ViewChild('contextSelectorUl') contextSelectorUl!: ElementRef;
  @ViewChild('userDropDownLi') userDropDownLi!: ElementRef;
  @ViewChild('menuDropLi') menuDropLi!: ElementRef;
  @ViewChild('menuDropSpan') menuDropSpan!: ElementRef;
  @ViewChildren('menuItem') menuItems!: QueryList<ElementRef<HTMLLIElement>>;
  @ViewChildren('droppedMenuItem') droppedMenuItems!: QueryList<ElementRef<HTMLLIElement>>;

  message: { [key: string]: string; } = {};

  appName = 'Admin';
  apps: Application[] = [];
  contextGroups: ContextGroup[] = [];
  profileRole = PROFILE_ROLE;

  isAppsDropdownOpen = false;
  isContextsDropdownOpen = false;
  isMenuDropOpen = false;
  isDroppedAppsMenuOpen = false;
  contextSelectorWidth: number = 0;
  navbarBrandInitialWidth: number = 0;
  menuItemsInitialWidth: number = 0;
  showHelp: boolean = false;

  appsAriaLabel: string = '';
  infoAriaLabel: string = '';

  isDarkMode = false;
  pictureStyle: string = '';
  profile!: Profile;

  unlistenerClick: any;
  unlistenerKeyup: any;

  hostname: string = HOSTNAME.localhost;
  selectedTenant!: Tenant;
  rootContextPath: string = '/';

  private readonly translationIndex = 'navbar';
  private readonly translationIndexHelp = 'help';
  private readonly translationIndexCommon = 'common';

  private resize$ = new Subscription();
  private click$ = new Subscription();
  private keyup$ = new Subscription();
  private services$ = new Subscription();
  private tenantResponse$ = new Subscription();
  private patchTheme$ = new Subscription();
  private patchTenant$ = new Subscription();
  private showHelp$ = new Subscription();

  constructor(private profileService: ProfileService, private i18nService: I18NService,
    private authService: AuthService, private service: NavbarService,
    private renderer2: Renderer2, private windowRef: WindowRef,
    private welcomeDialogDataService: WelcomeDialogDataService) { }

  ngOnInit() {
    this.hostname = this.windowRef.getHostname();

    this.profile = this.profileService.profile;
    this.rootContextPath = this.service.getRootContextPath(this.profile.currentRole);
    this.selectedTenant = JSON.parse(JSON.stringify(this.profile.currentTenant));
    this.message = {
      ...this.i18nService.messages[this.translationIndex], ...this.i18nService.messages[this.translationIndexHelp],
      ...this.i18nService.messages[this.translationIndexCommon]
    };
    this.isDarkMode = this.profileService.isDarkMode(this.profile.theme);
    this.appName = this.service.getAppName(this.profile.currentRole, this.message);

    this.appsAriaLabel = this.i18nService.getFormattedMessage(this.message['applicationsAriaLabel'],
      [this.appName]);
    this.pictureStyle = `background-image: url(${this.profile.picture});`;
    this.infoAriaLabel = this.i18nService.getFormattedMessage(this.message['informationAriaLabel'],
      [this.profile.currentTenant.organization_name]);

    const page = 1;
    const limit = AUTOCOMPLETE_MAX_LIMIT;

    this.tenantResponse$ = this.authService.getTenantResponse(this.hostname, page, limit).pipe(first()).subscribe(data => {
      this.contextGroups = this.service.getContextGroups(data.tenants, this.message);

      this.services$ = this.service.getApplications().pipe(first()).subscribe(response => {
        this.apps = response;

        this.navbarBrandInitialWidth = this.navbarBrandDiv.nativeElement.offsetWidth;
        this.menuItemsInitialWidth = this.service.getMenuItemsTotalWidth(this.menuItems.toArray());
        this.contextSelectorWidth = this.contextSelectorUl.nativeElement.offsetWidth;

        this.handleResponsiveness(this.navbarBrandInitialWidth, this.menuItemsInitialWidth,
          this.contextSelectorWidth);
      });

    });

    this.onShowHelp();
    this.documentClickListener();
    this.keyupListener();
    this.resizeListener();
  }

  ngOnDestroy(): void {
    this.resize$.unsubscribe();
    this.click$.unsubscribe();
    this.keyup$.unsubscribe();
    this.services$.unsubscribe();
    this.tenantResponse$.unsubscribe();
    this.patchTenant$.unsubscribe();
    this.patchTheme$.unsubscribe();
    this.showHelp$.unsubscribe();
  }

  onChangeMode(event: Event, isDarkMode: boolean) {
    event.preventDefault();
    let theme = THEME.light;
    this.isDarkMode = !isDarkMode;
    if (this.isDarkMode) {
      theme = THEME.dark;
      this.profileService.changeThemeToDark();
    } else {
      this.profileService.changeThemeToLight();
    }
    this.profileService.profile.theme = theme;
    this.patchTheme$ = this.authService.patchCustomWithTheme(theme).subscribe();
  }

  toggleAppsDropdown(event: Event) {
    event.preventDefault();
    this.isAppsDropdownOpen = !this.isAppsDropdownOpen;
    if (this.isAppsDropdownOpen) {
      this.isContextsDropdownOpen = false;
      this.isMenuDropOpen = false;
    }
  }

  toggleMenuDrop(event: Event) {
    event.preventDefault();
    this.isMenuDropOpen = !this.isMenuDropOpen;
    if (this.isMenuDropOpen) {
      this.isAppsDropdownOpen = false;
      this.isContextsDropdownOpen = false;
    }
  }

  toggleDroppedAppsMenu(event: Event) {
    event.preventDefault();
    this.isDroppedAppsMenuOpen = !this.isDroppedAppsMenuOpen;
  }

  toggleContextsDropdown(event: Event) {
    event.preventDefault();
    this.isContextsDropdownOpen = !this.isContextsDropdownOpen;
    if (this.isContextsDropdownOpen) {
      this.isAppsDropdownOpen = false;
      this.isMenuDropOpen = false;
    }
  }

  onSearchContext(event: any): void {
    const page = 1;
    const limit = AUTOCOMPLETE_MAX_LIMIT;
    if (event.originalEvent.type === 'click') {
      this.tenantResponse$ = this.authService.getTenantResponse(this.hostname, page, limit).pipe(first()).subscribe(data => {
        this.contextGroups = this.service.getContextGroups(data.tenants, this.message);
      });
    } else {
      this.tenantResponse$ = this.authService.getTenantResponse(this.hostname, page, limit, event.query).pipe(first()).subscribe(data => {
        if (data === null) {
          this.contextGroups = [];
        } else {
          this.contextGroups = this.service.getContextGroups(data.tenants, this.message);
        }
      });
    }
  }

  onSelectContext(event: AutoCompleteSelectEvent): void {
    const tenant: Tenant = event.value;
    if (this.profileService.profile.currentTenant.tenant_id !== tenant.tenant_id) {
      this.profileService.profile.currentTenant = tenant;
      this.patchTenant$ = this.authService.patchCustomWithLastUsedTenant(tenant.tenant_id, tenant.organization_name).subscribe(() => {
        this.authService.setCurrentTenantOnStorage(tenant);
        const url = this.authService.getConnectLoginUrl(this.hostname);
        this.windowRef.setLocationHref(url);
      });
    }
  }

  onBlurContext(): void {
    this.selectedTenant = JSON.parse(JSON.stringify(this.profile.currentTenant));
  }

  onClickHelp(event: Event): void {
    event.preventDefault();
    this.showHelp = true;
  }

  closeHelp(): void {
    this.showHelp = false;
  }

  private resizeListener(): void {
    this.resize$ = fromEvent(window, 'resize')
      .pipe(debounceTime(50))
      .subscribe(() => {
        this.handleResponsiveness(this.navbarBrandInitialWidth, this.menuItemsInitialWidth,
          this.contextSelectorWidth);
      });
  }

  private documentClickListener(): void {
    this.click$ = fromEvent(document, 'click').subscribe((event: Event) => {
      this.closeAppsDropdown(event);
      this.closeContextsDropdown(event);
    });
  }

  private closeAppsDropdown(event: Event): void {
    if (this.isAppsDropdownOpen) {
      if (event.target !== this.appsToggle.nativeElement && !this.appsToggle.nativeElement.contains(event.target)) {
        this.isAppsDropdownOpen = false;
      }
    }
  }

  private closeContextsDropdown(event: Event): void {
    if (this.isContextsDropdownOpen) {
      if (event.target !== this.contextsToggle.nativeElement && event.target !== this.contextsMenu.nativeElement &&
        !this.contextsToggle.nativeElement.contains(event.target) && !this.contextsMenu.nativeElement.contains(event.target)) {
        this.isContextsDropdownOpen = false;
      }
    }
  }

  private keyupListener(): void {
    this.keyup$ = fromEvent(document, 'keyup').subscribe((event: Event) => {
      const e = (event as KeyboardEvent);
      const c = e.key;
      if (e.altKey) {
        if (this.isExpectedKey(c, 'W')) {
          this.showHelp = !this.showHelp;
        }
        if (this.isExpectedKey(c, 'P')) {
          this.toggleAppsDropdown(event);
        }
        if (this.isExpectedKey(c, 'K')) {
          this.toggleContextsDropdown(event);
        }
        if (this.isExpectedKey(c, 'U')) {
          this.windowRef.open('https://docs.google.com/forms/d/e/1FAIpQLSeL252hiiMNwQfQy4-NH9sO5bIrTBCRWacdrXRPfaH5UnYT8g/viewform', '_blank');
        }
      }
    });
  }

  private onShowHelp(): void {
    this.showHelp$ = this.welcomeDialogDataService.openHelpPanel$.subscribe(() => {
      this.showHelp = true;
    });
  }

  private isExpectedKey(c: string, key: string): boolean {
    let is = false;
    if (c === key || c === key.toLowerCase()) {
      is = true;
    }
    return is;
  }

  private handleResponsiveness(navbarBrandWidth: number, menuItemsWidth: number, contextSelectorWidth: number): void {
    const userDropdownEl = this.userDropDownLi.nativeElement;

    if (this.service.isMobile()) {
      this.handleMobileSize(this.navbarDefaultHeader.nativeElement, this.navbarBrandDiv.nativeElement,
        this.menuDropSpan.nativeElement, this.navbarBrandLi.nativeElement);
      this.renderer2.addClass(this.userDropDownLi.nativeElement, 'icon');
      this.renderer2.removeClass(this.menuDropLi.nativeElement, 'hidden');
      this.hideAllMenuItems();
      return;
    }

    if (this.service.hasAllElementsDisplayed(navbarBrandWidth, menuItemsWidth, contextSelectorWidth)) {
      this.handleScreenSize(this.navbarDefaultHeader.nativeElement, this.navbarBrandDiv.nativeElement,
        this.menuDropSpan.nativeElement, this.navbarBrandLi.nativeElement);
      this.renderer2.removeClass(this.userDropDownLi.nativeElement, 'icon');
      this.renderer2.addClass(this.menuDropLi.nativeElement, 'hidden');
      this.showAllMenuItems();
      return;
    }

    this.renderer2.addClass(userDropdownEl, 'icon');
    this.handleElements();
  }

  private handleElements() {
    const navbarDefaultHeaderEl = this.navbarDefaultHeader.nativeElement;
    const navbarBrandDivEl = this.navbarBrandDiv.nativeElement;
    const contextSelectorUlEl = this.contextSelectorUl.nativeElement;
    const menuDropLiEl = this.menuDropLi.nativeElement;
    const menuDropSpanEl = this.menuDropSpan.nativeElement;
    const navbarBrandLiEl = this.navbarBrandLi.nativeElement;
    const arrMenuItems = this.menuItems.toArray();
    const arrDroppedMenuItems = this.droppedMenuItems.toArray();

    this.handleScreenSize(navbarDefaultHeaderEl, navbarBrandDivEl, menuDropSpanEl, navbarBrandLiEl);
    this.refreshMenuItems(arrMenuItems, arrDroppedMenuItems);
    this.renderer2.removeClass(menuDropLiEl, 'hidden');

    let width = document.body.clientWidth - navbarBrandDivEl.offsetWidth - contextSelectorUlEl.offsetWidth - menuDropLiEl.offsetWidth;
    this.handleMenuItems(arrMenuItems, arrDroppedMenuItems, width);

    if (this.service.hasDroppedMenuItems(arrMenuItems)) {
      this.renderer2.removeClass(menuDropLiEl, 'hidden');
    } else {
      this.renderer2.addClass(menuDropLiEl, 'hidden');
    }

    if (this.service.hasDroppedAllMenuItems(arrMenuItems)) {
      this.handleMobileSize(navbarDefaultHeaderEl, navbarBrandDivEl, menuDropSpanEl, navbarBrandLiEl);
    } else {
      this.handleScreenSize(navbarDefaultHeaderEl, navbarBrandDivEl, menuDropSpanEl, navbarBrandLiEl);
    }
  }

  private hideAllMenuItems(): void {
    const arrMenuItems = this.menuItems.toArray();
    const arrDroppedMenuItems = this.droppedMenuItems.toArray();
    for (let i = 0, len = arrMenuItems.length; i < len; i++) {
      this.renderer2.removeClass(arrDroppedMenuItems[i].nativeElement, 'hidden');
      this.renderer2.addClass(arrMenuItems[i].nativeElement, 'hidden');
    }
  }

  private showAllMenuItems(): void {
    const arrMenuItems = this.menuItems.toArray();
    const arrDroppedMenuItems = this.droppedMenuItems.toArray();
    for (let i = 0, len = arrMenuItems.length; i < len; i++) {
      this.renderer2.addClass(arrDroppedMenuItems[i].nativeElement, 'hidden');
      this.renderer2.removeClass(arrMenuItems[i].nativeElement, 'hidden');
    }
  }

  handleMenuItems(arrMenuItems: ElementRef<HTMLLIElement>[], arrDroppedMenuItems: ElementRef<HTMLLIElement>[], width: number): void {
    const activeIndex = this.service.getActivelMenuItemIndex(arrMenuItems);
    const cw = arrMenuItems[activeIndex].nativeElement.offsetWidth;
    width -= cw;
    this.handleMenuItem(arrMenuItems, arrDroppedMenuItems, activeIndex, width);

    for (let i = 0; i < activeIndex; i++) {
      const cw = arrMenuItems[i].nativeElement.offsetWidth;
      width -= cw;
      this.handleMenuItem(arrMenuItems, arrDroppedMenuItems, i, width);
    }

    for (let len = arrMenuItems.length, i = len - 1; i > activeIndex; i--) {
      const cw = arrMenuItems[i].nativeElement.offsetWidth;
      width -= cw;
      this.handleMenuItem(arrMenuItems, arrDroppedMenuItems, i, width);
    }
  }

  private handleMenuItem(arrMenuItems: ElementRef<HTMLLIElement>[], arrDroppedMenuItems: ElementRef<HTMLLIElement>[], index: number, width: number): void {
    if (width < 0) {
      this.renderer2.removeClass(arrDroppedMenuItems[index].nativeElement, 'hidden');
      this.renderer2.addClass(arrMenuItems[index].nativeElement, 'hidden');
    } else {
      this.renderer2.addClass(arrDroppedMenuItems[index].nativeElement, 'hidden');
      this.renderer2.removeClass(arrMenuItems[index].nativeElement, 'hidden');
    }
  }

  private handleMobileSize(navbarDefaultHeaderEl: HTMLElement, navbarBrandDivEl: HTMLDivElement,
    menuDropSpanEl: HTMLSpanElement, navbarBrandLiEl: HTMLLIElement): void {
    this.renderer2.addClass(navbarDefaultHeaderEl, 'is-mobile');
    this.renderer2.addClass(navbarBrandDivEl, 'hidden');
    this.renderer2.removeClass(menuDropSpanEl, 'hidden');
    this.renderer2.removeClass(navbarBrandLiEl, 'hidden');
  }

  private handleScreenSize(navbarDefaultHeaderEl: HTMLElement, navbarBrandDivEl: HTMLDivElement,
    menuDropSpanEl: HTMLSpanElement, navbarBrandLiEl: HTMLLIElement): void {
    this.renderer2.removeClass(navbarDefaultHeaderEl, 'is-mobile');
    this.renderer2.removeClass(navbarBrandDivEl, 'hidden');
    this.renderer2.addClass(menuDropSpanEl, 'hidden');
    this.renderer2.addClass(navbarBrandLiEl, 'hidden');
  }

  private refreshMenuItems(arrMenuItems: ElementRef<HTMLLIElement>[], arrDroppedMenuItems: ElementRef<HTMLLIElement>[]): void {
    for (let i = 0, len = arrMenuItems.length; i < len; i++) {
      this.renderer2.addClass(arrDroppedMenuItems[i].nativeElement, 'hidden');
      this.renderer2.removeClass(arrMenuItems[i].nativeElement, 'hidden');
    }
  }

}
