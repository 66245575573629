export const ALERT_ERROR_NAME = {
    main: 'mainErrorAlert',
    dialogTabDetails: 'dialogTabDetailsErrorAlert',
    dialogTabMembers: 'dialogTabMembersErrorAlert',
    dialogTabServices: 'dialogTabServicesErrorAlert',
    dialogTabServiceAccess: 'dialogTabServiceAccessErrorAlert',
    dialogTabChangeLog: 'dialogTabChangeLogErrorAlert',
    dialogTabSubscriptions: 'dialogTabSubscriptionsErrorAlert',
    dialogTabConfiguration: 'dialogTabConfigurationErrorAlert'
}
