import { Component, Input } from '@angular/core';

@Component({
  selector: 'adminui-help-customer',
  standalone: true,
  imports: [],
  templateUrl: './help-customer.component.html'
})
export class HelpCustomerComponent {
  @Input({ alias: 'rootPath', required: true }) rootPath!: string;
  @Input({ alias: 'message', required: true }) message: { [key: string]: string; } = {};
  @Input({ alias: 'show', required: true }) show: boolean = false;
}
