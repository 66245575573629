import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { retry, throwError, timer } from 'rxjs';
import { DomainService } from '../domain/domain.service';
import { WindowRef } from '../domain/window-ref';

export const baseUrlInterceptor: HttpInterceptorFn = (req, next) => {
  const domainService = inject(DomainService);
  const windowRef = inject(WindowRef);
  const retryStrategy$ = (error: HttpErrorResponse) => {
    const shoudRetry = error.status !== 401 && error.status !== 403 && error.status !== 404 && error.status !== 405 && error.status !== 0;
    return shoudRetry ? timer(500) : throwError(() => error);
  }

  if (domainService.isUrlExcludedFromRetryStrategy(req.url) || domainService.isConnectUrl(req.url)) {
    return next(req);
  } else {
    const hostname = windowRef.getHostname();
    const baseUrl = domainService.getBaseUrl(hostname);
    const clone = req.clone({
      url: `${baseUrl}${req.url}`
    });

    return next(clone)
      .pipe(retry({ count: 1, delay: (err) => retryStrategy$(err) }));
  }
};

