import { Component, Input } from '@angular/core';

@Component({
  selector: 'adminui-help-company',
  standalone: true,
  imports: [],
  templateUrl: './help-company.component.html',
})
export class HelpCompanyComponent {
  @Input({ alias: 'rootPath', required: true }) rootPath!: string;
  @Input({ alias: 'message', required: true }) message: { [key: string]: string; } = {};
  @Input({ alias: 'show', required: true }) show: boolean = false;
}
