import { Injectable } from '@angular/core';
import { AlertErrorMessage } from './alert-error-message';
import { HttpErrorResponse } from '@angular/common/http';
import { ALERT_ERROR_NAME } from './alert-error-name';
import { I18NService } from '../../core/i18n/i18n.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertErrorDataService {

  private _alertMessage = new Subject<AlertErrorMessage>();
  public alertMessage$: Observable<AlertErrorMessage> = this._alertMessage.asObservable();

  private _alertErrorName = ALERT_ERROR_NAME.main;
  private translationIndex = 'error';
  private translation: { [key: string]: string } = {
    failedWithStatus: 'failed with status',
    400: 'Bad request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not found',
    409: 'Conflict',
    415: 'Unsupported Media Type',
    429: 'Too Many Requests',
    500: 'Internal server error',
    0: 'Request aborted',
    unknownError: 'Unknown error',
    errorCode: 'Error code',
    ACCESS_TOKEN_ERROR: 'Invalid tenant',
    APPLICATION_TYPE_NOT_FOUND_BY_INVARIANT_KEY: 'Application Type with invariant key {0} not found',
    APPLICATION_TYPE_NOT_FOUND_FOR_COMPANY: 'Cannot find company application for company with id {0} and application type {1}',
    APPLICATION_INSTANCE_GROUP_NOT_FOUND: 'Application Instance Group linked to Application type ID {0} not found',
    ACCOUNT_ID_CONFLICT: 'AccountId conflict'
  };

  constructor(private readonly i18nService: I18NService) { }

  get alertErrorName() {
    return this._alertErrorName;
  }

  set alertErrorName(value: string) {
    this._alertErrorName = value;
  }

  addAlertMessage(alertErrorName: string, type: string, error: HttpErrorResponse | HttpErrorResponse[]): void {
    const t = this.i18nService.messages[this.translationIndex];
    if (t !== undefined && !this.i18nService.isEmpty(t)) {
      this.translation = t;
    }

    const errors = this.getErrors(error);
    const errorCode = this.translation['errorCode'];
    let arr = [];
    for (let error of errors) {
      const xcid = error.headers.get('x-amzn-trace-id');
      const guid = xcid ? xcid : '';
      const status = error.status;
      const message = this.getMessage(error, this.translation);
      arr.push({ guid: guid, status: status, message: message });
    }
    this._alertMessage.next({ alertErrorName: alertErrorName, type: type, errorCode: errorCode, errors: arr });
  }

  reset(): void {
    this._alertMessage.next(new AlertErrorMessage());
  }

  private getErrors(error: HttpErrorResponse | HttpErrorResponse[]): HttpErrorResponse[] {
    let errors = [];
    if (Array.isArray(error)) {
      errors = error;
    } else {
      errors.push(error);
    }
    return errors;
  }

  private getMessage(errorResponse: HttpErrorResponse, translation: { [key: string]: string }): string {
    if (!errorResponse.error) {
      return translation[errorResponse.status] || errorResponse.statusText;
    }
    if (errorResponse.error.error) {
      return translation[errorResponse.status] || errorResponse.statusText;
    }

    let message = this.getMessageByErrorCode(errorResponse, translation);
    if (message === '') {
      message = this.getMessageByErrorMessage(errorResponse, translation);
    }
    return message;
  }

  private getMessageByErrorCode(errorResponse: HttpErrorResponse, translation: { [key: string]: string }): string {
    let message = '';
    const code = errorResponse.error.code;
    if (code) {
      const text = translation[code];
      if (text) {
        message = this.i18nService.getFormattedMessage(text, errorResponse.error.messageParameters);
      }
    }
    return message;
  }

  private getMessageByErrorMessage(errorResponse: HttpErrorResponse, translation: { [key: string]: string }): string {
    let message = errorResponse.error.message;
    if (!message) {
      message = errorResponse.error;
      if (this.i18nService.isEmpty(message)) {
        message = translation[errorResponse.status] || errorResponse.statusText;
      }
    }
    return message;
  }
}
