import { Injectable } from '@angular/core';
import { AlertFlashMessage, DEFAULT_MILLISECONDS } from './alert-flash-message';
import { ALERT_FLASH_NAME } from './alert-flash-name';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertFlashDataService {

  private _alertMessage = new Subject<AlertFlashMessage>();
  public alertMessage$: Observable<AlertFlashMessage> = this._alertMessage.asObservable();

  private _alertFlashName = ALERT_FLASH_NAME.main;
  private timeout: any;
  

  addAlertMessage(alertFlashName: string, type: string, text: string, miliseconds?: number): void {
    const msec = miliseconds ? miliseconds : DEFAULT_MILLISECONDS;
    this._alertFlashName = alertFlashName;
    this.reset();
    this._alertMessage.next({ alertFlashName: alertFlashName, type: type, text: text });
    this.timeout = setTimeout(() => {
      this.reset();
    }, msec);
  }

  reset(): void {
    clearTimeout(this.timeout);
    this._alertMessage.next({ alertFlashName: this._alertFlashName, type: '', text: '' });
  }
}
