import { Injectable } from "@angular/core";
import { TOKEN_RESPONSE } from "../../common/core/auth/connect-storage-keys";
import { PROFILE_ROLE } from "../../common/core/auth/profile-role";
import { ProfileService } from "../../common/core/auth/profile.service";
import { TokenResponse } from "../../common/core/auth/token-response";
import { ENTITY_TYPE } from "../../common/core/const/entity-type";
import { AdminWindow } from "../../common/core/domain/admin-window";
import { DomainService } from "../../common/core/domain/domain.service";
import { WindowRef } from "../../common/core/domain/window-ref";
import { WootricHelperService } from "./wootric-helper.service";


@Injectable({
  providedIn: 'root'
})
export class WootricService {

  private worker!: Worker;

  constructor(private domainService: DomainService, private windowRef: WindowRef, private profileService: ProfileService,
    private wootricHelperService: WootricHelperService) { }

  init(msgText: string, msgLink: string) {

    if (typeof Worker !== 'undefined') {
      const hostname = this.windowRef.getHostname();
      const baseUrl = this.domainService.getBaseUrl(hostname);

      if (typeof (this.worker) === 'undefined') {
        this.worker = this.wootricHelperService.getWorker();
      }

      this.worker.onmessage = ({ data }) => {
        const w = (window as AdminWindow & typeof globalThis);
        const options = this.wootricHelperService.getOptions(this.profileService.profile, data, msgText, msgLink);
        w.wootricSettings = options;
        w.wootric('run');
      };

      const str = sessionStorage.getItem(TOKEN_RESPONSE);
      if (str) {
        const config = this.domainService.getConnectIdentityConfig(hostname);
        const tr: TokenResponse = JSON.parse(str);
        this.worker.postMessage({
          baseUrl: baseUrl,
          bearer: tr.access_token,
          tenantId: this.profileService.profile.currentTenant.tenant_id,
          graphApi: config.graph_api,
          mainRole: this.profileService.profile.currentRole
        });
      }
    } else {
      console.log('Web Workers are not supported in this environment.');
    }
  }

  terminate(): void {
    try {
      this.worker.terminate();
    } catch (e) { }
  }

}
