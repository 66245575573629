import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertFlashDataService } from './alert-flash-data.service';
import { ALERT_FLASH_NAME } from './alert-flash-name';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'adminui-alert-flash',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert-flash.component.html'
})
export class AlertFlashComponent implements OnInit, OnDestroy {

  @Input({ alias: 'alertFlashName', required: true }) alertFlashName: string = '';

  alertClass: string = '';
  iconClass: string = '';
  text: string = '';
  alertPrefix = 'toast-';
  iconPrefix = 'vismaicon-'
  show = false;

  alertFlashNameConst = ALERT_FLASH_NAME;
  private alertFlashMessage$ = new Subscription();

  constructor(private readonly alertFlashDataService: AlertFlashDataService, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.changeDetectorRef.detach();
    
    this.alertFlashMessage$ = this.alertFlashDataService.alertMessage$.subscribe(alertFlashMessage => {
      if (alertFlashMessage.alertFlashName === this.alertFlashName) {
        if (alertFlashMessage.type !== '' && alertFlashMessage.text !== '') {
          this.alertClass = `toast-${alertFlashMessage.type}`;
          this.iconClass = `vismaicon-${alertFlashMessage.type}`;
          this.text = alertFlashMessage.text;
          this.show = true;
          this.changeDetectorRef.detectChanges();
        } else {
          this.show = false;
          this.changeDetectorRef.detectChanges();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.alertFlashMessage$.unsubscribe();
  }

  close() {
    this.show = false;
    this.alertFlashDataService.reset();
  }
}
