<div class="drawer drawer-lg help-drawer" [ngClass]="{'show': show}" role="dialog">
    <div class="drawer-header">
        <button type="button" class="close" (click)="close()" tabindex="0" #closeButton>Close</button>
        <span aria-hidden="true" class="vismaicon vismaicon-filled vismaicon-lg vismaicon-help"></span>
        <p></p>
        @switch(currentRole) {
        @case(profileRole.subscriberAdministrator) {
        <h2>{{message['getStartedWithAdmin']}}</h2>
        } @case(profileRole.companyAdministrator) {
        <h2>{{message['getStartedWithAdminCompany']}}</h2>
        }
        }
        <p class="help-info">
            @switch(currentRole) {
            @case(profileRole.subscriberAdministrator) {
            {{message['infoServiceAccessIsFilteredByTheCheckboxesNextToUsersCompaniesAndRoles']}}
            <br />
            {{message['infoIfYouSelectCheckboxesInOneOrTwoListsYouWillSeeTheServiceAccessForThoseSelections']}}
            <br />
            {{message['infoIfYouSelectCheckboxesInAllThreeListsYouCanAlsoGrantServiceAccess']}}
            } @case(profileRole.companyAdministrator) {
            {{message['infoServiceAccessIsFilteredByTheCheckboxesNextToUsersAndRoles']}}
            <br />
            {{message['infoIfYouSelectCheckboxesInOneListYouWillSeeTheServiceAccessForTheSelections']}}
            <br />
            {{message['infoIfYouSelectCheckboxesInBothListsYouCanAlsoGrantServiceAccess']}}
            }
            }
        </p>
    </div>
    <div class="drawer-body">
        @switch(currentRole){
        @case(profileRole.subscriberAdministrator) {
        <adminui-help-customer [message]="message" [rootPath]="rootPath" [show]="show"></adminui-help-customer>
        }
        @case(profileRole.companyAdministrator) {
        <adminui-help-company [message]="message" [rootPath]="rootPath" [show]="show"></adminui-help-company>
        }
        }
    </div>
</div>
