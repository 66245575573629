import { AlertError } from "./alert-error";

export class AlertErrorMessage {
    alertErrorName: string = '';
    type: string = '';
    errorCode: string = '';
    errors: AlertError[] = [];
    static readonly TYPE = {
        http: 'http',
        client: 'client'
    };
}
