import { Component } from '@angular/core';
import { WelcomeDialogDataService } from './welcome-dialog-data.service';
import { I18NService } from './common/core/i18n/i18n.service';

@Component({
  selector: 'adminui-welcome-dialog',
  standalone: true,
  imports: [],
  templateUrl: './welcome-dialog.component.html',
})
export class WelcomeDialogComponent {
  readonly translationIndex = 'welcomeDialog';

  message: { [key: string]: string } = {};

  constructor(private i18nService: I18NService, private welcomeDialogDataService: WelcomeDialogDataService) { }

  ngOnInit(): void {
    this.message = { ...this.i18nService.messages[this.translationIndex] };
  }

  showMeChanges(): void {
    this.welcomeDialogDataService.closeDialog();
    this.welcomeDialogDataService.openHelpPanel();
  }

  cancel(): void {
    this.welcomeDialogDataService.closeDialog();
  }

}
